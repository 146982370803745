import request from '@/utils/request'

// 查询剧本推荐列表
export function listScriptRecommend(query) {
  return request({
    url: '/script/script/recommend/list',
    method: 'get',
    params: query
  })
}

// 查询剧本推荐详细
export function getScriptRecommend(id) {
  return request({
    url: '/script/script/recommend/' + id,
    method: 'get'
  })
}

// 新增剧本推荐
export function addScriptRecommend(data) {
  return request({
    url: '/script/script/recommend',
    method: 'post',
    data: data
  })
}

// 修改剧本推荐
export function updateScriptRecommend(data) {
  return request({
    url: '/script/script/recommend',
    method: 'put',
    data: data
  })
}

// 删除剧本推荐
export function delScriptRecommend(id) {
  return request({
    url: '/script/script/recommend/' + id,
    method: 'delete'
  })
}
